import { mapGetters, mapActions, mapMutations } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import moment from 'moment'
import { EntitiesTypes } from '@/helper/entityType'

import {
  NewEntity,
  DeleteEntity,
  EmptyView,
  EntityDrawer,
  CrudButtons,
  SvgLoader,
  Loading,
  SimpleEntity
} from '@/components'

export default {
  data () {
    return {
      entityTitle: '',
      managerDeleteEntity: false,
      managerNewEntity: false,
      showSelectedEntity: false,
      isEditingEntity: false,
      isLoading: false,
      isLoadingScreen: false
    }
  },
  components: {
    NewEntity,
    DeleteEntity,
    EmptyView,
    EntityDrawer,
    CrudButtons,
    SvgLoader,
    Loading,
    SimpleEntity
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot',
      'getterSelectedEntity',
      '$getterFormatDate'
    ]),
    creationDate () {
      const newDate = this.getterSelectedEntity.creationDate ? this.getterSelectedEntity.creationDate.split('/') : '-'
      return newDate.length > 1 ? moment(`${newDate[2]}-${newDate[1]}-${newDate[0]}`).format(this.$getterFormatDate) : '-'
    }
  },
  watch: {
    getterSelectedEntity () {
      this.isEditingEntity = false
    }
  },
  mounted () {
    if (this.$route.name !== 'Entities') {
      this.$router.push({ name: 'Entities' })
    }

    this.SET_SELECTED_ENTITY()
    this.getAllByIdBot(true)
  },
  methods: {
    ...mapActions([
      'actionEditEntity',
      'actionGetAllExpressionsByIdEntity'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    ...mapMutations([
      'SET_SELECTED_ENTITY'
    ]),
    setToDefault () {
      this.isLoading = false
      this.isEditingEntity = false
    },
    enableToEdit () {
      const element = document.querySelector('.entityTitle')

      setTimeout(() => {
        element.click()
      }, 250)

      this.entityTitle = this.getterSelectedEntity.name
      this.isEditingEntity = true
    },
    editEntity (e) {
      if (e.keyCode === 27) {
        this.isEditingEntity = false
      }

      if (e.keyCode === 13 || e.button === 0) {
        if (!this.entityTitle) {
          this.addToast({
            text: this.$t('entity.toast.invalidEntityName'),
            type: 'warning'
          })
          return
        }

        this.isLoading = true

        const bot = {
          idBotVersion: this.getterSelectedBot.botVersions[0].idBotVersion,
          idBot: this.getterSelectedBot.idBot
        }

        if (this.getterSelectedEntity.type === EntitiesTypes.COMPOSED || this.getterSelectedEntity.type === EntitiesTypes.HIERARCHICAL) {
          let idsOfChildsEntity = []

          this.getterSelectedEntity.children.forEach(childs => {
            idsOfChildsEntity = idsOfChildsEntity.concat({ id: childs.id })
          })

          const entity = {
            ...this.getterSelectedEntity,
            children: idsOfChildsEntity,
            name: this.entityTitle
          }

          this.executeAction(this.actionEditEntity, { bot, entity })
        } else {
          const entity = {
            ...this.getterSelectedEntity,
            name: this.entityTitle
          }

          this.executeAction(this.actionEditEntity, { bot, entity })
        }
      }
    },
    executeAction (actionToEdit, params) {
      return actionToEdit(params)
        .then(() => {
          this.isLoading = false
          this.isEditingEntity = false
        })
        .catch(err => {
          if (err.status === 400) {
            this.addToast({
              text: this.$t('entity.toast.isNotPossible'),
              type: 'warning',
              dismissAfter: 5000
            })
          }

          this.isLoading = false
          this.isEditingEntity = false
        })
    },
    getAllByIdBot (callback) {
      if (callback && (this.getterSelectedEntity.type === EntitiesTypes.SIMPLE || this.getterSelectedEntity.type === EntitiesTypes.COMPOSED || this.getterSelectedEntity.type === EntitiesTypes.HIERARCHICAL)) {
        this.isLoadingScreen = true

        const data = {
          idBotVersion: this.getterSelectedBot.botVersions[0].idBotVersion,
          idEntity: this.getterSelectedEntity.id
        }

        this.actionGetAllExpressionsByIdEntity(data)
          .then(() => {
            this.isLoadingScreen = this.isEditingEntity = false
          }).catch(() => {
            this.addToast({
              text: this.$t('entity.toast.errorFetchingExpressions'),
              type: 'danger'
            })

            this.isLoadingScreen = false
          })
      }
    }
  }
}
