var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-entities internal-page"
  }, [_vm.managerDeleteEntity ? _c('deleteEntity', {
    attrs: {
      "bot-color": _vm.getterSelectedBot.botColor
    },
    on: {
      "callback": function callback(event) {
        return _vm.managerDeleteEntity = event;
      }
    }
  }) : _vm._e(), _c('entityDrawer', {
    attrs: {
      "bot-color": _vm.getterSelectedBot.botColor
    },
    on: {
      "toNewEntity": function toNewEntity(event) {
        return _vm.managerNewEntity = event;
      },
      "callback": function callback(value) {
        return _vm.getAllByIdBot(value);
      }
    }
  }), _vm.managerNewEntity ? _c('newEntity', {
    attrs: {
      "bot-color": _vm.getterSelectedBot.botColor
    },
    on: {
      "callback": function callback(event) {
        return _vm.managerNewEntity = event;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "container-detailed-content"
  }, [Object.keys(_vm.getterSelectedEntity).length === 0 ? _c('emptyView', {
    attrs: {
      "icon-path": "/img/icon-entities.svg",
      "icon-color": "#ff5326",
      "title": _vm.$t('entity.title'),
      "message": _vm.$t('entity.message')
    }
  }) : _vm._e(), Object.keys(_vm.getterSelectedEntity).length > 0 && Object.keys(_vm.getterSelectedEntity).length > 0 ? _c('section', {
    staticClass: "is-selected-item",
    class: {
      'processing': _vm.getterSelectedEntity.processing,
      'isTimeOut': _vm.getterSelectedEntity.isTimeOut
    },
    on: {
      "click": function click($event) {
        return _vm.setToDefault();
      }
    }
  }, [Object.keys(_vm.getterSelectedEntity).length > 0 ? _c('div', {
    staticClass: "container-box container-content-entity-detailed common-space"
  }, [_c('div', {
    staticClass: "container-content-title-entity"
  }, [_c('div', {
    staticClass: "wrapper-title",
    class: {
      'isEditing': _vm.isEditingEntity,
      'isLoading': _vm.isLoading
    },
    on: {
      "click": function click(e) {
        e.stopPropagation();
      }
    }
  }, [_vm.isLoading && !_vm.getterSelectedEntity.processing ? _c('loading') : _vm._e(), _c('div', {
    staticClass: "container-title"
  }, [_c('label', {
    staticClass: "entityTitle",
    attrs: {
      "for": "entityTitle"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.entityTitle,
      expression: "entityTitle",
      modifiers: {
        "trim": true
      }
    }, {
      name: "remove-extra-white-spaces",
      rawName: "v-remove-extra-white-spaces"
    }],
    staticClass: "search-with-animation",
    attrs: {
      "id": "entityTitle",
      "type": "text",
      "name": "entityTitle",
      "maxlength": "50"
    },
    domProps: {
      "value": _vm.entityTitle
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.editEntity($event);
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.entityTitle = $event.target.value.trim();
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('h6', [_vm._v(_vm._s(_vm.getterSelectedEntity.name))])]), _vm.getterSelectedEntity.typeName !== 'Prebuilt' ? _c('section', {
    staticClass: "header-actions",
    class: {
      'disabled': _vm.isLoadingScreen
    }
  }, [_c('crudButtons', {
    attrs: {
      "toogle-is-editing": function toogleIsEditing() {
        return _vm.enableToEdit();
      },
      "is-editing": _vm.isEditingEntity,
      "to-edit": function toEdit(e) {
        return _vm.editEntity(e);
      },
      "to-delete": function toDelete() {
        return _vm.managerDeleteEntity = true;
      },
      "disable-edit": _vm.isLoading
    }
  }, [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.auto",
      value: {
        content: _vm.$t('entity.tooltip.useButton'),
        offset: 10
      },
      expression: "{ content: $t('entity.tooltip.useButton'), offset: 10 }",
      modifiers: {
        "auto": true
      }
    }],
    staticClass: "btn-question"
  }, [_c('svgLoader', {
    attrs: {
      "src": "/img/icon-question.svg",
      "color": "#fff"
    }
  })], 1)])], 1) : _vm._e()], 1), _c('ul', {
    staticClass: "entity-info"
  }, [_c('li', [_c('span', [_vm._v(_vm._s(_vm.$t('entity.type')))]), _c('strong', [_vm._v(_vm._s(_vm.getterSelectedEntity.typeName))]), _c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.auto",
      value: {
        content: _vm.$t('entity.tooltip.typeEntity'),
        offset: 10
      },
      expression: "{ content: $t('entity.tooltip.typeEntity'), offset: 10 }",
      modifiers: {
        "auto": true
      }
    }],
    staticClass: "btn-question"
  }, [_c('svgLoader', {
    attrs: {
      "src": "/img/icon-question.svg",
      "color": "#fff"
    }
  })], 1)]), _c('li', [_c('span', [_vm._v(_vm._s(_vm.$t('entity.dateCreate')))]), _c('strong', [_vm._v(_vm._s(_vm.creationDate))])])])]), _c('div', {
    staticClass: "container-content-entity"
  }, [_vm.isLoadingScreen || _vm.getterSelectedEntity.processing ? _c('loading') : _vm._e(), !_vm.isLoadingScreen ? _c('router-view') : _vm._e()], 1)]) : _vm._e()]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }